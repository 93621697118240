.jstree-default .jstree-hovered {
  text-decoration: underline;
  background: $light-background;
  color: $light-color;
}

.jstree-default-dark .jstree-hovered {
  text-decoration: underline;
  background: $dark-background;
  color: $dark-color;
}

.jstree-default .jstree-clicked {
  background: $light-background;
  color: $light-color;
}

.jstree-default-dark .jstree-clicked {
  background: $dark-background;
  color: $dark-color;
}

#page-tree {
  display: none;
}